/* eslint-disable import/order */
import { whyDidYouRender } from '../wdyr';
import React from 'react';
import { ClientContext } from '@thd-nucleus/app-render/dist/client';
import B2BTheme from '@thd-olt-global/b2b-theme';
import { NewRelicBridge } from '@thd-olt-component-react/new-relic-bridge';
import { ThdAnalytics } from '@thd-olt-component-react/thd-analytics';
import { App } from './App';
import { getRoutes } from './routes';

ThdAnalytics.init();
NewRelicBridge.init();
whyDidYouRender.init();

document.addEventListener('DOMContentLoaded', async () => {
  const clientContext = new ClientContext({ opts: { federation: true } });
  clientContext.setExperienceRoutes(getRoutes());
  if (clientContext.customer.type === 'b2b') {
    clientContext.setTheme({ name: 'b2b', template: B2BTheme });
  }
  clientContext.render(<App
    prefetchPip={clientContext.featureSwitches.prefetchPip}
    productPodHoverDelay={clientContext.featureSwitches.productPodHoverDelay}
  />);
});

import React from 'react';
import { enableWhyDidYouRender } from './project-config.json';

const whyDidYouRender = {
  init: () => {
    if (process.env.NODE_ENV === 'development' && enableWhyDidYouRender) {
      // eslint-disable-next-line global-require
      const render = require('@welldone-software/why-did-you-render');
      render(React, {
        logOnDifferentValues: true,
        include: [
          /^AvailableEvents/
        ]
      });
    }
  }
};
export { whyDidYouRender };
